import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0 36px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0 24px;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0 16px;
    flex-direction: column;
  }
`

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const DescriptionWrapper = styled.div`
  max-width: 588px;
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${size.lg}) {
    max-width: 700px;
  }

  @media (max-width: ${size.md}) {
    max-width: 600px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }
`

import step1 from '../../../../static/img/Emdr/step-1.png'
import step2 from '../../../../static/img/Emdr/step-2.png'
import step2Small from '../../../../static/img/Emdr/step-2-small.png'
import step3 from '../../../../static/img/Emdr/step-3.png'
import step4 from '../../../../static/img/Emdr/step-4.png'
import step4Small from '../../../../static/img/Emdr/step-4-small.png'

import styled from 'styled-components'
import { Button, Text } from '../../../../youtalk-storybook/src/ui'
import { Montserrat, size } from '../../../constants'

export const Wrapper = styled.div`
  background-color: #fff;
`

export const Content = styled.div`
  padding: 72px 0 54px;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const InfoContainer = styled.div`
  max-width: 588px;

  @media (max-width: ${size.lg}) {
    max-width: 460px;
  }

  @media (max-width: ${size.md}) {
    max-width: 356px;
  }

  @media (max-width: ${size.sm}) {
    max-width: unset;
    width: 100%;
  }
`
export const Br = styled.br`
  @media (max-width: ${size.md}) {
    display: none;
  }
`

export const BrOneBlockThree = styled.br`
  @media (max-width: ${size.sm}) {
    display: none;
  }
`
export const BrTwoBlockThree = styled.br`
  display: none;
  @media (max-width: ${size.sm}) {
    display: block;
  }
`

export const BlockWrapper = styled.div`
  padding: 18px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media (max-width: ${size.md}) {
    padding: 12px 0;
    gap: 16px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0;
  }
`
export const TitleSteps = styled.p`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: ${(props) =>
    props.bold ? '700' : props.semiBold ? '500' : '400'};
  font-size: 32px;
  line-height: 40px;
  margin: 0;

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const TitleList = styled.p`
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TitleWrapper = styled.div`
  flex: 1;

  ${TitleSteps} {
    color: ${(props) => props.color ?? '#333'};
    margin-bottom: 18px;

    @media (max-width: ${size.md}) {
      margin-bottom: 12px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 0;
    }
  }

  ${TitleList} {
    margin-bottom: 16px;
    @media (max-width: ${size.xxs}) {
      margin-bottom: 8px;
    }
  }

  ${Text.Large} {
    margin-bottom: 16px;
    @media (max-width: ${size.xxs}) {
      margin-bottom: 8px;
    }
  }
`

export const ListWrapper = styled.ul`
  max-width: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  padding-left: 25px;
  list-style-type: disc;
`
export const ListItem = styled.li`
  gap: 8px;
  color: #333;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dfebf9;
  border-radius: 16px;

  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    width: 356px;
    height: 614px;
  }

  @media (max-width: ${size.sm}) {
    order: -1;
    width: 100%;
    height: 374px;
  }

  @media (max-width: ${size.xs}) {
    height: 288px;
  }
`

export const ImageOne = styled.img.attrs(() => ({
  src: step1
}))`
  width: 330px;
  height: 442px;

  @media (max-width: ${size.lg}) {
    width: 228px;
    height: 317px;
  }

  @media (max-width: ${size.xs}) {
    width: 171px;
    height: 238px;
  }
`

export const ImageTwo = styled.img.attrs(() => ({}))`
  content: url(${step2});
  width: 538px;
  height: 321px;

  @media (max-width: ${size.lg}) {
    width: 336px;
    height: 243px;
  }

  @media (max-width: ${size.md}) {
    content: url(${step2Small});
    height: 241px;
  }

  @media (max-width: ${size.xs}) {
    width: 252px;
    height: 180px;
  }
`

export const ImageThree = styled.img.attrs(() => ({
  src: step3
}))`
  width: 262px;
  height: 422px;

  @media (max-width: ${size.lg}) {
    width: 197px;
    height: 317px;
  }

  @media (max-width: ${size.xs}) {
    width: 147px;
    height: 237px;
  }
`

export const ImageFour = styled.img.attrs(() => ({}))`
  content: url(${step4});
  width: 540px;
  height: 280px;

  @media (max-width: ${size.lg}) {
    width: 404px;
    height: 210px;
  }

  @media (max-width: ${size.md}) {
    content: url(${step4Small});
    width: 336px;
    height: 212px;
  }

  @media (max-width: ${size.xs}) {
    width: 249px;
    height: 159px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

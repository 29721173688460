import cardOneIcon from '../../../../static/img/Emdr/card-one-img.svg'
import cardTwoIcon from '../../../../static/img/Emdr/card-two-img.svg'
import styled from 'styled-components'
import { Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const InfoContainer = styled.div``

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }

  margin-bottom: 32px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const TitleCardWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }
`

export const Container = styled.div`
  margin: auto;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #fff;
  border-radius: 32px;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    align-items: center;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${size.lg}) {
    gap: 32px;
  }

  @media (max-width: ${size.md}) {
    gap: 24px;
  }

  @media (max-width: ${size.sm}) {
    gap: 16px;
  }
`

export const CardOneImage = styled.img.attrs(() => ({
  src: cardOneIcon
}))``

export const CardTwoImage = styled.img.attrs(() => ({
  src: cardTwoIcon
}))``

export const TextWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

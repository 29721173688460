import blockIcon from '../../../../static/img/Emdr/findYourEmdr-image.svg'
import blockIcon1024 from '../../../../static/img/Emdr/findYourEmdr-image-1024.svg'
import blockIcon320 from '../../../../static/img/Emdr/findYourEmdr-image-320.svg'
import blockIcon414 from '../../../../static/img/Emdr/findYourEmdr-image-414.svg'
import blockIcon768 from '../../../../static/img/Emdr/findYourEmdr-image-768.svg'
import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const BlockImage = styled.img.attrs(() => ({}))`
  content: url(${blockIcon});
  width: 588px;
  height: 588px;

  @media (max-width: ${size.lg}) {
    content: url(${blockIcon1024});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    height: 341px;
    content: url(${blockIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${blockIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${blockIcon320});
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #fff;
`

export const InfoContainer = styled.div``

import React from 'react'
import {
  BlockImage,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FindYourEmdr.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const FindYourEmdr = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите специалиста и запишитесь на консультацию прямо сейчас!
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Решение обратиться к психотерапевту может быть вызовом, но вы
                уже сделали первый шаг! Сотрудники компании YouTalk готовы
                помочь вам на всех этапах: заполните анкету, и наш эксперт по
                подбору специалистов найдет для вас наиболее подходящего
                психолога. Или вы можете самостоятельно выбрать его с помощью
                нашего алгоритма.
              </Text.Large>
              <Text.Large semiBold>
                У нас всегда есть возможность задать вопросы или ознакомиться со
                статьями в блоге, чтобы успокоиться и развеять сомнения.
                Руководства и списки помогут вам подготовиться к первой сессии,
                а психолог будет поддерживать вас по пути к решению ваших
                проблем.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <BlockImage alt="Подберите специалиста и запишитесь на консультацию прямо сейчас!" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)

import React from 'react'
import {
  CardContainer,
  CardOneImage,
  CardTwoImage,
  Container,
  ImageWrapper,
  InfoContainer,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './HowEmdrIsGoing.styles'
import { Content } from '../Common.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const HowEmdrIsGoing = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>Как проходят онлайн сессии EMDR-терапии?</Title.H2>
            </TitleWrapper>
            <CardContainer>
              <Container>
                <ImageWrapper>
                  <CardOneImage />
                </ImageWrapper>
                <InfoContainer>
                  <TextWrapper>
                    <Text.Large semiBold>
                      Во время сессии психолог просит клиента сосредоточиться на
                      значимом событии, которое эмоционально беспокоит, обратить
                      внимание на негативные мысли о себе, чувства, ощущения в
                      теле и начать двигать глазами влево-вправо, и/или
                      прохлопывать себя, может добавить звуковую стимуляцию. С
                      движением глаз ускоряется процесс появления ассоциаций,
                      воспоминаний, и мы можем соединиться с
                      замороженными/вытесненными переживаниями и{' '}
                      <a
                        href="https://pubmed.ncbi.nlm.nih.gov/25989952/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        переработать их
                      </a>
                      .
                    </Text.Large>
                  </TextWrapper>
                </InfoContainer>
              </Container>
              <Container>
                <ImageWrapper>
                  <CardTwoImage />
                </ImageWrapper>
                <InfoContainer>
                  <TextWrapper>
                    <Text.Large semiBold>
                      Переработанное воспоминание не забывается, но больше не
                      вызывает таких сильных эмоций как раньше. Например,
                      события из детства перестают влиять на наше настоящее. В
                      процессе переработки, когда мы активно двигаем глазами,
                      мозг совершает интенсивную работу, формируются новые
                      нейронные связи, поэтому нередко после консультаций клиент
                      чувствует усталость.
                    </Text.Large>
                  </TextWrapper>
                </InfoContainer>
              </Container>
            </CardContainer>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)

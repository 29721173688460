import React from 'react'
import loveIcon from '../../../../static/img/Emdr/love-icon.svg'
import messageIcon from '../../../../static/img/Emdr/message-icon.svg'
import studyIcon from '../../../../static/img/Emdr/study-icon.svg'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './AdantagesEmdr.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const AdantagesEmdr = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2>Преимущества EMDR-терапии в YouTalk</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                YouTalk позволяет получать консультации из любого места и в
                любое время. Наши психологи живут в разных часовых поясах,
                поэтому вы найдете специалиста, проводящего консультации в
                удобное для вас время.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={studyIcon} />
              <Text.Large semiBold>
                Психологи YouTalk получили профессиональное образование, прошли
                обучение EMDR и обладают опытом работы в этом подходе.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                Консультации в YouTalk анонимны. Нам важен лишь ваш контакт для
                связи с психологом и администратором. Всю остальную личную
                информацию вы не обязаны раскрывать, если не хотите.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={loveIcon} />
              <Text.Large semiBold>
                Точный подбор. Наши специалисты по подбору — квалифицированные
                психологи. Они уточнят ваш запрос и подберут специалиста,
                опираясь на десятки критериев. В YouTalk 97% клиентов находят
                своего психолога с первого раза.
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)

import heroIcon from '../../../../static/img/Emdr/Emdr-hero.svg'
import heroIcon1024 from '../../../../static/img/Emdr/Emdr-hero-1024.svg'
import heroIcon768 from '../../../../static/img/Emdr/Emdr-hero-768.svg'
import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const HeroImage = styled.img.attrs(() => ({}))`
  content: url(${heroIcon});
  width: 588px;
  height: 588px;

  @media (max-width: ${size.lg}) {
    content: url(${heroIcon1024});
    width: 100%;
    height: 454px;
  }

  @media (max-width: ${size.md}) {
    content: url(${heroIcon768});
  }

  @media (max-width: ${size.sm}) {
    content: url(${heroIcon768});
    height: 227px;
  }

  @media (max-width: ${size.xs}) {
    content: url(${heroIcon});
    height: 288px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

export const InfoContainer = styled.div``

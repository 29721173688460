import imageOne from '../../../../static/img/Emdr/whatIsEmdr-one.svg'
import imageOne1024 from '../../../../static/img/Emdr/whatIsEmdr-one-1024.svg'
import imageOne768 from '../../../../static/img/Emdr/whatIsEmdr-one-768.svg'
import imageThree from '../../../../static/img/Emdr/whatIsEmdr-three.svg'
import imageThree1024 from '../../../../static/img/Emdr/whatIsEmdr-three-1024.svg'
import imageThree768 from '../../../../static/img/Emdr/whatIsEmdr-three-768.svg'
import imageTwo from '../../../../static/img/Emdr/whatIsEmdr-two.svg'
import imageTwo1024 from '../../../../static/img/Emdr/whatIsEmdr-two-1024.svg'
import imageTwo768 from '../../../../static/img/Emdr/whatIsEmdr-two-768.svg'
import styled from 'styled-components'
import { Button, Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }

  margin-bottom: 36px;

  @media (max-width: ${size.md}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const ImageOne = styled.img.attrs(() => ({}))`
  content: url(${imageOne});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${imageOne1024});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    content: url(${imageOne768});
    width: 100%;
    height: 341px;
  }
`

export const ImageTwo = styled.img.attrs(() => ({}))`
  content: url(${imageTwo});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${imageTwo1024});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    content: url(${imageTwo768});
    width: 100%;
    height: 341px;
  }
`
export const ImageThree = styled.img.attrs(() => ({}))`
  content: url(${imageThree});
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    content: url(${imageThree1024});
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.md}) {
    content: url(${imageThree768});
    width: 100%;
    height: 341px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #fff;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 36px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    flex-direction: column;
    margin-bottom: 48px;

    &:nth-of-type(3) {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`

import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './EmdrHero.styles'
import { Content, DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const EmdrHero = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H1>
                Записаться к EMDR
                <br />
                онлайн терапевту
              </Title.H1>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Запишитесь к EMDR специалисту по работе с травмами, ПТСР и
                интенсивными эмоциями. EMDR психологи YouTalk — высококлассные
                специалисты, которые помогут справиться с последствиями тяжелых
                событий и выстроить комфортную для вас жизнь.
              </Text.Large>
              <SeoPriceForTitleBlock />
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Записаться к EMDR онлайн терапевту" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)

import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
    flex-direction: column;
  }
`

export const ListWrapper = styled.ul`
  max-width: 588px;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  padding-left: 25px;
  list-style-type: disc;
`
export const ListItem = styled.li`
  gap: 8px;

  &:marker {
    color: #fff;
  }
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};
  margin-bottom: 16px;

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 8px;
  }
`

export const InfoContainer = styled.div``

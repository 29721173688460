import React from 'react'
import styled from 'styled-components'
import { Button, Text } from '../../../../youtalk-storybook/src/ui'
import {
  CardContainer,
  Content,
  SunImage,
  TextWrapper,
  Wrapper
} from './InfoChatCard.styles'
import { Event as GAEvent } from '../../../components/GA'
import { size } from '../../../constants'

const ButtonToForm = styled(Button.NewPrimary).attrs({
  href: '/wizard/',
  onClick: GAEvent.openSemeinyiPageFirstScreen,
  size: 'large',
  type: 'link'
})`
  display: flex;

  @media (max-width: ${size.md}) {
    width: 608px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const InfoChatCard = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <SunImage />
          <TextWrapper>
            <Text.Large bold>
              Предоставьте себе возможность получить ту поддержку и помощь,
              которую вы заслуживаете
            </Text.Large>
          </TextWrapper>
        </CardContainer>
        <ButtonToForm>Подобрать психолога</ButtonToForm>
      </Content>
    </section>
  </Wrapper>
)

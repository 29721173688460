import React from 'react'
import { Content, DescriptionWrapper, Wrapper } from './HowToFindEmdr.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const HowToFindEmdr = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>
              Как найти и выбрать психолога
              <br /> специалиста по EMDR (ДПДГ)?
            </Title.H2>
          </TitleWrapper>
          <DescriptionWrapper>
            <Text.Large semiBold>
              ДПДГ психологов значительно меньше, чем, например, гештальт или
              КПТ терапевтов, т.к. это подход менее «популярный». Из-за этого
              найти хорошего EMDR-специалиста может быть непросто, а выбор будет
              невелик. Однако это ничуть не умаляет его эффективности и
              результатов.
            </Text.Large>
            <Text.Large semiBold>
              Вы можете выбрать специалиста самостоятельно из нашего каталога
              или оставить заявку на подбор психолога специалистом по подбору.
              Выбирая из нескольких психологов, вы можете опираться на
              собственные предпочтения, информацию о специалисте и запросах,
              <br />с которыми он работает.
            </Text.Large>
          </DescriptionWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
